<template>
    <div class="chart-box">
      <div class="operate-box">
        <el-select
          clearable
          v-model="storeId"
          class="dialogInput"
          remote
          :remote-method="getAllStoreList"
          filterable
          placeholder="请选择门店"
          @focus="getAllStoreList"
          @change="storeIdChange"
        >
          <el-option
            v-for="item in allStoreList"
            :key="item.id"
            :label="item.storeName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div class="ysb-checkbox">
          <el-checkbox
            v-model="ysbChecked"
            @change="ysbCheckedChange"
          >
            <div>养身帮连锁门店</div>
          </el-checkbox>
          <el-popover
            placement="bottom-end"
            width="280"
            trigger="click"
            content="指自营店、联营店、加盟店，不含合作店"
          >
            <img
              class="issue-icon"
              slot="reference"
              src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png"
            />
          </el-popover>
        </div>
        <el-button class="operate-box__btn" :class="[timeType == 0 ? 'operate-box__btn--active' : '']" @click="weekChange" :type="timeType == 0 ? 'primary' : ''">
          近一周
        </el-button>
        <el-select class="operate-box__select" v-model="month" placeholder="月份" @change="monthChange">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker
          class="operate-box__picker"
          v-model="times"
          @change="timesChange"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div ref="userBrokenLineChart" id="user-broken-line__chart">

      </div>
    </div>
</template>
<script>
import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import { allStoreList } from "@/api/store";
import * as echarts from "echarts";

import { getWeeklyData } from "@/api/home";
import dayjs from "dayjs";

@Component
export default class WeekDay extends Vue {
    @Ref("userBrokenLineChart") userBrokenLineChart;

    dayCountList = [];

    detectionCountList = []

    healthCountList = []

    userCountList = []

    timeType = 0; // 0-周 1-月 2-自定义时间

    allStoreList = [];

    storeId = "";

    options = [
      { value: 1, label: "一月" },{ value: 2, label: "二月" },{ value: 3, label: "三月" },{ value: 4, label: "四月" },
      { value: 5, label: "五月" },{ value: 6, label: "六月" },{ value: 7, label: "七月" },{ value: 8, label: "八月" },
      { value: 9, label: "九月" },{ value: 10, label: "十月" },{ value: 11, label: "十一月" },{ value: 12, label: "十二月" },
    ]
    month = "";

    times = [];

    chart = null;

    dataZoomEnd = 100;

    ysbChecked = false;

    chartOptions = {
        backgroundColor: 'white',
        grid: {
          top: '15%',
          left: '3%',
          right: '5%',
          bottom: '100px',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          borderWidth: 1,
          axisPointer: {
            type: 'shadow'
          },
          extraCssText: 'z-index:2'

        },
        dataZoom: [
            {
                xAxisIndex: 0,//这里是从X轴的0刻度开始
                show: true,//是否显示滑动条，不影响使用
                type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
                start: 0, // 从头开始。
                end: this.dataZoomEnd,
                bottom: "50"
            }
        ],
        legend: [{
          bottom: "10",
          orient: 'horizontal',
          data: ['用户量', '检测量', '调理量'],
          itemWidth: 30,
          itemHeight: 10,
          itemGap: 15,
          borderRadius: 4,
          textStyle: {
            color: '#000',
            fontFamily: 'Alibaba PuHuiTi',
            fontSize: 14,
            fontWeight: 400
          }
        }],
        xAxis: {
          name: "时间",
          type: 'category',
          nameTextStyle: {
              color: '#999',
              fontSize: 14,
            },
          data: this.dayCountList,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#393939' //X轴文字颜色
            }
          }
        },
        yAxis: [
          {
            type: 'value',
            name: "数量",
            nameTextStyle: {
              color: '#999',
              fontSize: 14,
            },
            nameGap: 30,  // 表现为上下位置
            axisLine: {
              show: true,
              lineStyle: {
                color: '#eeeeee'
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#393939',
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#eeeeee'
              }
            }
          }

        ],
        series: [
          {
            name: '用户量',
            type: 'line',
            smooth: true,
            showAllSymbol: true, //显示所有图形。
            //标记的图形为实心圆
            symbolSize: 8, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: 'white',
              borderWidth: '2',
              normal: {
                color: '#566fc4'//拐点颜色
              }
            },
            label: {
                show: true,
                position: 'top'
            },
            lineStyle: {
              color: '#566fc4'
            },
            data: this.userCountList,
          },
          {
            name: '检测量',
            type: 'line',
            smooth: true,
            showAllSymbol: true, //显示所有图形。
            symbolSize: 8, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: 'white',
              borderWidth: '2',
              normal: {
                color: '#ed696a'//拐点颜色
              }
            },
            label: {
                show: true,
                position: 'top'
            },
            lineStyle: {
              color: '#ed696a'
            },
            data: this.detectionCountList
          },
          {
            name: '调理量',
            type: 'line',
            smooth: true,
            showAllSymbol: true, //显示所有图形。
            symbolSize: 8, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: 'white',
              borderWidth: '2',
              normal: {
                color: '#f9ca61'//拐点颜色
              }
            },
            label: {
                show: true,
                position: 'top'
            },
            lineStyle: {
              color: '#f9ca61'
            },
            data: this.healthCountList
          }
        ]
      }

    mounted() {
      this.getChartsData(0);
    }

    ysbCheckedChange() {
      this.getChartsData(this.timeType);
    }

    weekChange() {
      this.timeType = 0;
      this.month = undefined;
      this.times = [];
      this.getChartsData(this.timeType);
    }

    monthChange($event) {
      if (!$event) return;
      this.timeType = 1;
      this.times = [];
      this.getChartsData(this.timeType);
    }

    timesChange($event) {
      if (!$event.length) return;
      this.timeType = 2;
      this.month = "";
      this.getChartsData(this.timeType);
    }

    storeIdChange() {
        this.getChartsData(this.timeType);
    }

    getAllStoreList(str) {
      if (typeof str == "object") {
        str = "";
      }
      allStoreList({
        storeName: str,
      }).then((res) => {
        this.allStoreList = res.data;
      }).catch((err) => {});
    }

    drawChart() {
        if (!this.chart) {
            this.chart = echarts.init(this.userBrokenLineChart);
        }
        this.chart.setOption(this.chartOptions, true)
    }
    getWeeklyData(param = {}) {
        getWeeklyData(param).then((res) => {
          this.dayCountList.splice(0, this.dayCountList.length)
          this.detectionCountList.splice(0, this.detectionCountList.length)
          this.healthCountList.splice(0, this.healthCountList.length)
          this.userCountList.splice(0, this.userCountList.length)
          res.data?.forEach(el => {
            this.dayCountList.push(el.dayCount);
            this.detectionCountList.push(el.detectionCount);
            this.healthCountList.push(el.healthCount);
            this.userCountList.push(el.userCount);
          });
          this.drawChart();
        })
    }

    getChartsData(value) {
      const param = { isAllStore: Number(!this.ysbChecked) };
      if (value == 0) {
        const beginTime = dayjs().subtract(6, "day").format("YYYY-MM-DD 00:00:00")
        const dayCount = 7;
        Object.assign(param, { beginTime, dayCount })
      }
      if (value == 1) {
        const beginTime = dayjs().set('month', this.month - 1).startOf("month").format("YYYY-MM-DD 00:00:00");
        const dayCount = dayjs().set('month', this.month - 1).daysInMonth();
        Object.assign(param, { beginTime, dayCount })
      }
      if (value == 2) {
        const [start, end] = this.times;
        const beginTime = dayjs(start).format("YYYY-MM-DD 00:00:00");
        const dayCount = dayjs(end).diff(dayjs(start), 'day') + 1;
        Object.assign(param, { beginTime, dayCount })
      }
      this.dataZoomEnd = parseInt((7 / param.dayCount) * 100);
      this.chartOptions.dataZoom[0].end = this.dataZoomEnd;
      if (this.storeId && this.storeId > 0) {
        Object.assign(param, { storeId: this.storeId })
      }

      this.getWeeklyData(param)
    }

}
</script>
<style lang="scss" scoped>
.chart-box{
    border-radius: 25px;
    overflow: hidden;
    margin: 5px 0;
    background: #fff;
    position: relative;
    .operate-box{
      position: absolute;
      z-index: 2;
      right: 20px;
      top: 10px;
      display: flex;
      align-items: center;
      .operate-box__btn{
        width: 60px;
        height: 32px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        margin: 0;
        padding: 0;
        margin-right: 12px;
        &--active{
          background: #1677FF;
        }
      }
      .operate-box__select{
        width: 80px;
        height: 32px;
        margin-right: 12px;
        :deep(.el-input) {
          height: 32px;
        }
        :deep(.el-input__inner){
          height: 32px!important;
        }
        :deep(.el-input__suffix){
          margin-top: 5px 
        }
      }
      .operate-box__picker{
        height: 32px;
      }
    }
}
#user-broken-line__chart{
    width: 100%;
    height: 500px;
}
.ysb-checkbox {
  display: inline-flex;
  margin-left: 30px;
  align-items: center;
  .issue-icon {
    width: 12px;
    height: 12px;
    margin: 5px 0 0 5px;
    margin-right: 12px;
  }
}
</style>