import { service } from '@/ajax/request'


export const suggestionList = (params: any) => (
  service({
    url: '/device/tongue/suggestionList',
    method: 'GET',
    params,
    type: 'form'
  })
)


export const update = (params: any) => (
  service({
    url: '/device/tongue/update',
    method: 'POST',
    params,
    type: 'form'
  })
)


export const storeDiagnoses = (params: any) => (
  service({
    url: '/store/storeDiagnoses/list',
    method: 'POST',
    params,
    type: 'form'
  })
)


export const save = (params: any) => (
  service({
    url: '/store/storeDiagnoses/save',
    method: 'POST',
    params
  })
)

export const getDiagnosesTypeList = (params: any) => (
  service({
    url: '/store/storeDiagnoses/getDiagnosesTypeList',
    method: 'GET',
    params,
    type: 'form'
  })
)

export const getAllDiagnosesData = (params: any) => (
  service({
    url: '/store/storeDiagnoses/getAllDiagnosesData',
    method: 'GET',
    params,
    type: 'form'
  })
)


export const tongueProfileList = (params: any) => {
  return service({
    url: '/tongue-profile/list',
    method: 'POST',
    params
  })
}
export const tongueProfileEdit = (params: any) => {
  return service({
    url: '/tongue-profile/edit',
    method: 'POST',
    params
  })
}
export const tongueExplainList = (params: any) => {
  return service({
    url: '/tongue-explain/list',
    method: 'POST',
    params
  })
}
export const tongueExplainTypeList = (parentId: number | string) => {
  return service({
    url: `/tongue-explain/get-tongue-type-list/${parentId}`,
    method: 'POST',
  })
}
export const tongueExplainEdit = (params: any) => {
  return service({
    url: `/tongue-explain/save-or-update`,
    method: 'POST',
    params,
  })
}
export const tongueExplainDel = (id: number) => {
  return service({
    url: `/tongue-explain/delete/${id}`,
    method: 'POST',
  })
}





