import { service } from "@/ajax/request";

  // 获取用户相关数据
  export const getAllUserData = (params: any) =>
  service({
    url: "/sys/data/getAllUserData",
    method: "GET",
    params,
    type: "form",
  });

  // 获取门店相关数据
  export const getAllStoreData = (params: any) =>
  service({
    url: "/sys/data/getAllStoreData",
    method: "GET",
    params,
    type: "form",
  });

  // 获取检测和调理记录数据
  export const getAllDetectDigData = (params: any) =>
  service({
    url: "/sys/data/getAllDetectDigData",
    method: "GET",
    params,
    type: "form",
  });

  // 获取营业数据汇总
  export const getAllTotalData = (params: any) =>
  service({
    url: "/sys/data/getAllTotalData",
    method: "GET",
    params,
    type: "form",
  });

  // 获取营业数据汇总
  export const getDeviceAndBizData = (params: any) =>
  service({
    url: "sys/data/getDeviceAndBusinessData",
    method: "GET",
    params,
    type: "form",
  });

  // 获取金额
  export const getBusinessData = (params: any) =>
  service({
    url: "/sys/data/getBusinessData",
    method: "GET",
    params,
    type: "form",
  });

  // 获取金额
  export const getWeeklyData = (params: any) =>
  service({
    url: "/sys/data/getWeeklyData",
    method: "GET",
    params,
    type: "string",
  });